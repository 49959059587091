.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .containerApp {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
.d-flex {
    display: flex;
  }
  
  .flex-row {
    flex-direction: row;
  }
  
  .flex-column {
    flex-direction: column;
  }
  
  .flex-fill {
    flex: 1 1 auto;
  }
  
  .justify-content-center {
    justify-content: center;
  }
  
  .justify-content-end {
    justify-content: end;
  }
  
  .justify-content-around {
    justify-content: space-around;
  }
  
  .justify-content-between {
    justify-content: space-between;
  }
  
  .justify-content-evenly {
    justify-content: space-evenly;
  }
  
  .align-items-center {
    align-items: center;
  }
  
  .align-items-end {
    align-items: end;
  }
  
  .align-items-around {
    align-content: space-around;
  }
  
  .align-items-between {
    align-content: space-between;
  }
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  body {
    color: var(--text-color);
    margin: 0;
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--primary);
  }
  
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }
  
  ul {
    list-style: none;
    padding-left: 0px;
    margin: 0;
  }
  
  img {
    max-width: 100%;
    display: block;
  }
@use"./mixin" as mixin;

.btn {
  display: flex;
  margin-block: 15px;
  text-transform: uppercase;
  background-color: var(--action);
  border-radius: 10vh 0 10vh 0;
  font-weight: 800;
  padding: 7px 18px;
  font-size: 1rem;
  overflow: hidden;
  position: relative;
  transition: all .3s;
  z-index: 0;
  margin: 0 auto;
  cursor: pointer;

  @include mixin.xs {
    font-size: 0.8rem;
  }


  @include mixin.intxs {

    border-radius: 3vh 0 3vh 0;
    font-size: 0.8rem;
    margin-bottom: 10px;
  }


  @include mixin.intxxs {
    font-size: 0.7rem;
    padding: 4px 10px;
  }


  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--red);
    transition: ease-in-out .3s;
    border-radius: 10vh 0 10vh 0;
    z-index: -1;

    @include mixin.intxs {

      border-radius: 2vh 0 2vh 0;

    }
  }

  &:hover {
    color: var(--primary);
    transform: scale(1.1);

    &:before {
      width: 100%;
    }
  }
}

.active {
  border-bottom: 2px solid var(--red)
}

input {
  padding-block: 2%;
  border-radius: 10vh;
  padding-left: 3%;
  font-size: 1rem;
  font-style: italic;



}

label {
  font-size: 1.2rem;
  width: 100%;
  margin: 0 auto;
}

.form-error {
  color: var(--red);
  font-weight: 700;
  text-align: center;
  font-size: clamp(0.9rem, 1.1rem, 1.5rem);
  margin-top: 20px;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: black;
}

// .feedbackGood {
//   text-transform: uppercase;
//   background-color: var(--action);
//   border-radius: 10vh 0 10vh 0;
//   font-weight: 800;
//   padding: 7px 18px;
//   font-size: 1rem;
//   text-align: center;
//   width: 66%;
//   margin: 15px auto;
//   border: 2px solid var(--red)
// }


.form2 {
  display: flex;
  flex-direction: column;
  border-radius: 10vh 0 10vh 0;
  background-color: var(--blue);
  padding: 20px;
  border: 2px var(--red) solid;
  width: 50%;
  margin: 5% auto;

  @include mixin.md {
    width: 66%
  }

  @include mixin.sm-lt {
    width: 66%
  }

  @include mixin.sm {
    width: 80%;
  }

  @include mixin.xs {
    width: 82%;
  }

  @include mixin.intxs {
    width: 95%;
    padding: 5px;
    border-radius: 5vh 0 5vh 0;
  }

  @include mixin.xxs {
    width: 90%;
  }

  @include mixin.intxxs {
    width: 95%;
  }


  h2 {
    text-align: center;

    @include mixin.intxs {
      font-size: 1.1rem;
      width: 80%;
      margin: 0 auto;
    }
  }

  .labelAdmin {
    width: 90%;
    margin: 20px auto 0 auto;

    @include mixin.intxs {
      font-size: 1rem;
    }

  }

  .inputAdmin {
    padding: 10px;
    width: 90%;
    margin: 0 auto;

    @include mixin.intxs {
      padding: 5px;
      font-size: 1rem;
    }
  }

  @include mixin.intxs {
    padding: 5px;
    font-size: 1rem;
  }

  input[type=file] {
    font-size: 0.7rem;
    border-radius: 1vh 0 1vh 0
  }

  ;

  input[type=file]::file-selector-button {

    padding: 8px;
    font-size: 0.7rem;

    border-radius: 1vh 0 1vh 0;

    @include mixin.intxs {
      padding: 8px;
      border-radius: 3vh 0 3vh 0;
      font-size: 0.6rem;
    }

  }


  select {
    padding: 5px;
    border-radius: 10vh;
    margin-bottom: 20px;
  }

  textarea {
    box-sizing: border-box;
    width: 90%;
    margin: 0 auto;
    height: 20vh;
    border-radius: 30px;
    font-size: 20px;
    font-family: var(--font-family);
    padding: 10px
  }

}

.fw600 {
  font-weight: 600;
}

.fw200 {
  font-weight: 200;
  font-style: italic;
}

.feedbackGoodLight {
  text-align: center;
  color: var(--blue);
  font-weight: 600;
  font-size: clamp(1rem, 1.2rem, 1.5rem);
  margin-top: 15px;
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: black;
}

.feedbackGood {
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: clamp(0.8rem, 1.3rem, 1.7rem);
  margin-top: 15px;
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: black;
}

.rules {
  margin: 0 auto;
  width: 40%;

  @include mixin.sm-lt {
    width: 50%;
  }

  @include mixin.intxs {
    width: 75%;
  }

  .linkMentions {
    color: var(--red);
    cursor: pointer;
  }
}

.formatAccepted {
  font-size: 0.7rem;
  font-style: italic
}
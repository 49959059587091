@use"../../../assets/styles/mixin" as mixin;

.nav {
    width: 75%;
    margin: 10% auto 2% auto;

    .ul {
        font-size: 0;

        .li {
            text-align: center;
            margin-block: 4%;


            a {
                text-align: center;
                font-size: 1.8rem;
                padding: 5% 0;
                letter-spacing: 5px;
                text-decoration: none;
                color: var(--text-color);

                @include mixin.intxl {
                    font-size: 1.6rem;
                }

                @include mixin.md-lt {
                    font-size: 1.5rem;
                }

                @include mixin.intxxs {
                    font-size: 1.5rem;
                }

                @include mixin.smallest {
                    font-size: 1.5rem;
                    letter-spacing: 3px;
                }
            }
        }
    }
}
.p5 {
  padding: 5px;
}

.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}

.m5 {
  margin: 5px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.m10 {
  margin: 10px;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb50 {
  margin-bottom: 50px;
}

.mr20 {
  margin-right: 20px;
}

.m20 {
  margin: 20px;
}

.ml20 {
  margin-left: 20px;
}

.m30 {
  margin: 30px;
}

.mt20 {
  margin-top: 20px;
}

.mt10 {
  margin: 10px
}

.mt50 {
  margin-top: 50px;
}

.mb20 {
  margin-bottom: 20px;
}

.my20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mx20 {
  margin-left: 20px;
  margin-right: 20px;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

.text-center {
  text-align: center;
}
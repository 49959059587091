@use"../../assets/styles/mixin" as mixin;


.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--red);
    border-radius: 5vh;
    position: relative;
    z-index: 4;

    align-items: center;
    padding-top: 0.2%;
    height: 4.8em;
    width: 4.8em;
    transition: .5s all;
    cursor: pointer;

    a {
        color: var(--red)
    }




    @include mixin.sm-lt {
        margin-left: 5%;
        height: 2.9em;
        width: 2.9em;
    }

    @include mixin.xs {
        margin-left: 10%;
        height: 2.7em;
        width: 2.7em;
    }

    @include mixin.intxs {
        margin-left: 10%;
        height: 2.7em;
        width: 2.7em;
    }


    &:hover {
        box-shadow: 10px 5px 5px var(--red);
    }

    i {
        font-size: 2.8em;
        transition: .5s ease-in-out;

        @include mixin.lg-lt {
            font-size: 2.2em;
        }

        @include mixin.xs {
            font-size: 1.8em;
        }

        @include mixin.xxs {
            font-size: 1.5em;
        }

        @include mixin.xxxs {
            font-size: 1.3em;
        }

        @include mixin.xxxxs {
            font-size: 1.5em;
        }

        @include mixin.smallest {
            font-size: 1.5em;
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    .check {
        i {
            color: rgb(4, 233, 4);
            font-size: 1.5em;
            position: absolute;
            bottom: 8%;
            right: 7%;

            @include mixin.sm-lt {
                bottom: -5px;
                right: -5px;
                font-size: 1.2em;
            }

            @include mixin.intxs {
                bottom: 0px;
                right: 2px;
                font-size: 1em;
            }

            @include mixin.intxs {
                font-size: 0.8em;
                right: 5px;
                bottom: 2px;
            }
        }


    }

    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75%;
        border-radius: 50%;
        height: 4em;
        width: 4em;

        @include mixin.sm-lt {
            height: 3em;
            width: 3em;
        }

        @include mixin.intxs {
            height: 2.5em;
            width: 2.5em;
        }

    }

    .profile {
        position: absolute;
        bottom: -165%;
        width: 35vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: var(--text-color);
        font-size: 1.5rem;
        background-color: transparent;
        border-radius: 10vh 0 10vh 0;
        z-index: 99;
        opacity: 0.95;

        transition: opacity ease 0.3s, transform ease 0.3s;

        @include mixin.sm-lt {
            bottom: -220%
        }





        .li {

            .btn {
                text-transform: uppercase;
                background-color: var(--action);
                border-radius: 10vh 0 10vh 0;
                font-weight: 800;
                padding: 0.8em 2em;
                font-size: 0.8rem;
                overflow: hidden;
                transition: all .3s;
                z-index: 0;
                cursor: pointer;
                margin-bottom: 5%;

                @include mixin.sm-lt {
                    font-size: 0.7rem;
                }

                @include mixin.sm-lt {
                    font-size: 0.6rem;
                }

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0%;
                    height: 100%;
                    background-color: var(--red);
                    transition: ease-in-out .3s;
                    border-radius: 10vh 0 10vh 0;
                    z-index: -1;
                }

                &:hover {
                    color: var(--primary);
                    transform: scale(1.05);

                    &:before {
                        width: 100%;
                    }
                }
            }

        }

    }

}

.login {
    position: absolute;
    // border: solid red 2px;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    // transform: scale(0);
    transition: opacity 0.3s, transform 0.3s, display 0.1s, visibility 0.1s;
}

.nolog {
    display: flex;
    justify-content: center;


    p {
        transition: opacity ease 0.3s, transform ease 0.3s;
        border: var(--red) solid 2px;
        width: 150%;
        position: absolute;
        top: 110%;
        transform: translate(-50%);
        text-transform: uppercase;
        background-color: var(--action);
        border-radius: 10vh 0 10vh 0;
        font-weight: 800;
        padding-block: 10px;
        text-align: center;
        font-size: 0.8rem;

        @include mixin.intxs {
            font-size: 0.6rem;
            width: 125%;
            border-radius: 5vh 0 5vh 0;
            padding-block: 5px;
        }
    }


}